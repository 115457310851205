import type DataGridControl from 'o365.controls.DataGrid.ts';

import { addEventListener } from 'o365.vue.composables.EventListener.ts';

export default class DataGridColumnMove {
    private _dataGridControl: DataGridControl;

    private _cleanupTokens: (() => void)[] = [];
    private _dragEndTasks: (() => void)[] = [];
    private _cachedColumns: Record<string, CachedColumn> = {};

    private _orderedColumns: OrderedColumnPolyfill[] = [];
    private _orderedColumnGroups: OrderedColumnGroupPolyfill[][] = [];

    private _movedInstance?: OrderedColumnPolyfill | OrderedColumnGroupPolyfill;

    private _dragImage: HTMLElement | undefined;
    private _dragImageLabel: HTMLElement | undefined;

    private _isDragging = false;
    /** Previous drag x position */
    private _prevX = 0;
    private _prevDirection?: string;
    private _currentOverColumn?: string;
    private _movedElement?: HTMLElement;
    private _isMoving = false;


    get container() {
        return this._dataGridControl.container;
    }
    get isDragging() { return this._isDragging; }

    get movedInstance() { return this._movedInstance; }

    constructor(pDataGridControl: DataGridControl) {
        this._dataGridControl = pDataGridControl;
    }

    initialize() {
        if (this.container == null) { return; }
        this._cleanupTokens.push(addEventListener(this.container, 'dragstart', this._onDragStart.bind(this)))
    }

    destory() {
        this._cleanupTokens.splice(0, this._cleanupTokens.length).forEach(ct => ct());
    }

    private _onDragStart(pEvent: DragEvent) {
        if (pEvent.dataTransfer == null || this.container == null) { return; }

        const target = pEvent.target as HTMLElement;
        const headerCell = target?.closest ? target?.closest<HTMLElement>("[o365-field][data-o365-colindex]") : null;
        let groupCell: HTMLElement | undefined = undefined;
        if (!headerCell || !headerCell.classList.contains('o365-header-cell')) {
            if (target.getAttribute('o365-header-group')) {
                groupCell = target;
            } else {
                return;
            }
        }

        this._cacheColumns();
        this._cacheColumnGroups();
        if (groupCell) {
            const location = groupCell?.getAttribute('o365-header-group')?.split('-').map(x => +x);
            if (location == null) { return; }
            this._movedInstance = this._orderedColumnGroups[location[0]]?.[location[1]];
        } else {
            const collId = target.getAttribute('o365-field')!;
            if (collId == null) { return; }
            pEvent.dataTransfer.effectAllowed = 'all';
            pEvent.dataTransfer.clearData();
            pEvent.dataTransfer.setData('text/plain', collId);
            pEvent.dataTransfer.setData('o365-nt/group-by-column', JSON.stringify({ colId: collId, type: 'column' }));
            this._movedInstance = this._orderedColumns.find(x => x.colId == collId);
        }
        if (this._movedInstance == null) { return; }
        const addReactiveGroupStyles = this._orderedColumnGroups.length > 0 && !isColumnGroup(this._movedInstance);

        if (addReactiveGroupStyles) {
            this.container.querySelectorAll('.o365-header-group-row').forEach(row => row.classList.add('d-none'));

            const createRowNodes = (pinned = 'none') => {
                const rowNodes = this._dataGridControl.dataColumns.columnGroups!.map((_group, index) => {
                    const node = document.createElement('div');
                    node.dataset.o365Rowindex = index + '';
                    node.classList.add('o365-header-row', 'o365-header-group-row', 'o365-header-group-row-cache', `pinned-${pinned}`);
                    return node;
                });
                return rowNodes;
            };
            this.container.querySelector('.o365-header-viewport .o365-grid-container')?.prepend(...createRowNodes());
            this.container.querySelector('.o365-header-pinned-left')?.prepend(...createRowNodes('left'));
            this.container.querySelector('.o365-header-pinned-right')?.prepend(...createRowNodes('right'));

            this._updateGroupCells();
        }

        this._prevX = pEvent.clientX;
        this._movedElement = target;

        this._movedInstance.initialOrder = this._movedInstance.order;
        this._initDragImage();

        this._dragImageLabel!.textContent = this._movedInstance.title!;
        this._dragImage!.style.width = this._movedInstance.width + 'px';
        document.body.appendChild(this._dragImage!);
        pEvent.dataTransfer.setDragImage(this._dragImage!, 0, 0);
        let cachedInstance: CachedColumn | null = null
        if (headerCell) {
            pEvent.dataTransfer.setData('o365-nt/column-order', JSON.stringify({ colId: this._movedInstance.colId, initialOrder: this._movedInstance.order }));
            cachedInstance = this._cachedColumns[this._movedInstance.colId!];
        } else {
            pEvent.dataTransfer.setData('o365-nt/column-order', JSON.stringify({ colId: this._movedInstance.colId, initialOrder: this._movedInstance.order }));
        }
        this.container.classList.add('o365-columns-moving');
        cachedInstance?.addClass('column-moving')
        this._isDragging = true;

        this._dragEndTasks.push(() => {
            this._isDragging = false;
            this._currentOverColumn = undefined;
            if (this.container == null) { return; }
            if (addReactiveGroupStyles) {
                this.container.querySelectorAll('.o365-header-group-row-cache').forEach(row => row.remove());
                this.container.querySelectorAll('.o365-header-group-row').forEach(row => row.classList.remove('d-none'));
            }
            this.container.classList.remove('o365-columns-moving');
            cachedInstance?.removeClass('column-moving');
            this._movedInstance = undefined;
            this._currentOverColumn = undefined;
            this._dragImage?.remove();
            this._prevX = 0;
            this._prevDirection = undefined;
            this._isDragging = false;
            this._movedElement = undefined;
        });
        this._initializeAutoScroll();
        if (this._dataGridControl.props.multilineHeader) {
            this._dragEndTasks.push(this._addMultilineStyles());
        }
        this._dragEndTasks.push(addEventListener(this.container, 'dragover', this._onDragOver.bind(this)));
        this._dragEndTasks.push(addEventListener(this.container, 'drop', this._onDrop.bind(this)));
        this._dragEndTasks.push(addEventListener(window, 'dragend', this._onDragEnd.bind(this)));
    }

    private _onDragOver(pEvent: DragEvent) {
        if (this._isMoving || !this._isOrderEvent(pEvent) || this._movedInstance == null || this._prevX == pEvent.clientX) { return; }
        pEvent.preventDefault();

        const target = pEvent.target as HTMLElement;
        const closestCell = target.closest<HTMLElement>('[o365-field][data-o365-colindex]');
        let colId: string|null = null;
        let targetIndex = -1;
        if (closestCell == this._movedElement) {
            return;
        } else if (closestCell == null) {
            const closestGroupCell = target.closest<HTMLElement>('[o365-header-group]');
            if (closestGroupCell == null || closestGroupCell == this._movedElement) { return; }
            const [groupRow, groupCol] = closestGroupCell.getAttribute('o365-header-group')!.split('-').map(x => +x);
            const overGroup = this._orderedColumnGroups[groupRow][groupCol];
            targetIndex = overGroup.order!;
            colId = overGroup.colId!;
        } else {
            targetIndex = parseInt(closestCell?.dataset.o365Colindex ?? '');
            colId = closestCell.getAttribute('o365-field');
        }
        if (!colId || this._movedInstance.colId == colId) { return; }
        if (isColumnGroup(this._movedInstance)) {
            if (this._movedInstance.children?.findIndex(x => x.colId == colId) != -1) { return ; }
        }
        const orderDirection = pEvent.clientX > (this._prevX ?? 0)
            ? 'right'
            : 'left';
        const directionChanged = orderDirection !== this._prevDirection;
        if (!directionChanged && targetIndex === this._movedInstance!.order) {
            return;
        }

        if (targetIndex === 0 || targetIndex >= this._orderedColumns.length - 1) {
            if (pEvent.dataTransfer == null) { return; }
            pEvent.dataTransfer.dropEffect = 'none';
            return;
        }

        const column = this._orderedColumns.find(x => x.colId === colId);
        if (!column) { return; }
        if (column.parentGroupId && isColumnGroup(this._movedInstance)) {
            const group = this._orderedColumnGroups[0].find(x => x.groupId == column.parentGroupId);
            
            colId = orderDirection == 'right'
                ? group!.children!.at(-1)!.colId!
                : group!.children![0].colId!;
        }

        if (this._movedInstance!.pinned != column.pinned) {
            if (pEvent.dataTransfer == null) { return; }
            pEvent.dataTransfer.dropEffect = 'none';
            return;
        }

        if (this._currentOverColumn === colId && this._prevDirection === orderDirection) {
            return;
        }

        this._currentOverColumn = colId;
        this._prevDirection = orderDirection;
        this._moveColumn();
        this._prevX = pEvent.clientX;
        if (!isColumnGroup(this._movedInstance)) {
            this._cacheColumnGroups();
        }
    }

    private _onDrop(pEvent: DragEvent) {
        if (!this._isOrderEvent(pEvent) || this._movedInstance == null) { return; }

        this._orderedColumns.forEach((col, index) => {
            const isMovedCol = isColumnGroup(this._movedInstance!) 
                ? this._movedInstance.children!.findIndex(x => x.colId == col.colId) != -1
                : this._movedInstance!.colId === col.colId;
            if (isMovedCol) {
                this._dataGridControl.dataColumns.getColumn(col.colId)?.trackChange('order', index);
            }
            this._dataGridControl.dataColumns.setColumnProperty(col.colId, 'order', index);
        });

        this._dragEndTasks.splice(0, this._dragEndTasks.length).forEach(ct => ct());
    }

    private _onDragEnd(_pEvent: DragEvent) {
        if (this._movedInstance != null) {
            this._orderedColumns.forEach((col, index) => {
                const isMovedCol = isColumnGroup(this._movedInstance!)
                    ? this._movedInstance.children!.findIndex(x => x.colId == col.colId) != -1
                    : this._movedInstance!.colId === col.colId;
                if (isMovedCol) {
                    this._dataGridControl.dataColumns.getColumn(col.colId)?.trackChange('order', index);
                }
                this._dataGridControl.dataColumns.setColumnProperty(col.colId, 'order', index);
            });
        }

        this._dragEndTasks.splice(0, this._dragEndTasks.length).forEach(ct => ct());
    }

    private _cacheColumns() {
        this._cachedColumns = {};
        this._orderedColumns.splice(0, this._orderedColumns.length);

        this._dataGridControl.dataColumns.columns.forEach((col) => {
            if (col.hide) {
                this._orderedColumns.push({
                    colId: col.colId,
                    order: col.order,
                    pinned: col.pinned ? (col.pinned as any) : null,
                    title: col.headerName ?? col.field ?? '',
                    parentGroupId: col.parentGroupId,
                    hide: true
                });
            } else {
                this._cachedColumns[col.colId] = new CachedColumn(col.colId, Array.from(this.container!.querySelectorAll(`[o365-field="${col.colId}"][data-o365-colindex]`)), col.width + col.widthAdjustment, Array.from(this.container!.querySelectorAll(`[o365-field="${col.colId}"][o365-header-group][draggable="true"]`)))
                const element = this.container!.querySelector<HTMLElement>(`.o365-header-cell.o365-header-cell-container[o365-field="${col.colId}"][data-o365-colindex]`)!;
                element.dataset.left = col.left as any;
                this._orderedColumns.push({
                    colId: col.colId,
                    order: col.order,
                    width: col.width + col.widthAdjustment,
                    pinned: col.pinned ? (col.pinned as any) : null,
                    title: col.headerName ?? col.field ?? '',

                    parentGroupId: col.parentGroupId,
                    element: element,
                    get left() { return parseInt(this.element.style.left) || parseInt(this.element.dataset.left) },
                })
            }
        });
    }

    private _cacheColumnGroups() {
        const columnGroupRows = this._dataGridControl.dataColumns.columnGroups?.length ?? 0;
        if (columnGroupRows == 0) { return; }
        this._orderedColumnGroups.splice(0, this._orderedColumnGroups.length);
        // this._dataGridControl.dataColumns.columns.filter(col => !col.hide).forEach((col, colIndex) => {
        this._orderedColumns.forEach((col, colIndex) => {
            if (col.parentGroupId) {
                let currentInstance: OrderedColumnGroupPolyfill = this._orderedColumns.find(x => x.colId == col.colId)!;
                for (let groupRowIndex = 0; groupRowIndex < columnGroupRows; groupRowIndex++) {
                    if (this._orderedColumnGroups[groupRowIndex] == null) { this._orderedColumnGroups[groupRowIndex] = []; }
                    const previousInstance = this._orderedColumnGroups[groupRowIndex].at(-1);

                    if (previousInstance && previousInstance.groupId == currentInstance.parentGroupId) {
                        if (previousInstance.children == null) { previousInstance.children = []; }
                        
                        previousInstance.children.push(currentInstance);
                        if (!previousInstance._groupCellsAppended && currentInstance.colId && !currentInstance.hide) {
                            const cachedCol = this._cachedColumns[currentInstance.colId]
                            cachedCol?.groupElements.push(...Array.from(this.container!.querySelectorAll<HTMLElement>(`[o365-header-group-id="${previousInstance.groupId}"][o365-header-group="${groupRowIndex}-${previousInstance.location![1]!}"]`)));
                            this._orderedColumnGroups[groupRowIndex].at(-1)!._groupCellsAppended = true;
                        }
                    } else {
                        const group = this._dataGridControl.dataColumns.columnGroups?.[groupRowIndex]?.find(x => x.groupId == currentInstance.parentGroupId)!;
                        const orderInRow = this._orderedColumnGroups[groupRowIndex].length;
                        this._orderedColumnGroups[groupRowIndex].push({
                            get colId() {
                                return this.children![0]?.colId;
                            },
                            groupId: group.groupId,
                            location: [groupRowIndex, orderInRow],
                            get order() {
                                return this.children![0]?.order;
                            },
                            set order(pValue) {
                                if (pValue != undefined) {
                                    this.children?.forEach((col, index) => {
                                        col.order = pValue + index;
                                    });
                                }
                            },
                            get pinned() {
                                return this.children![0]?.pinned;
                            },
                            title: group.headerName ?? '',
                            get hide() {
                                return this.children!.every((col: any) => col.hide);
                            },
                            parentGroupId: group.parentGroupId,
                            get width() {
                                return this.children!.reduce((sum: number, value: any) => {
                                    sum += value.width ?? 0;
                                    return sum;
                                }, 0 as number);
                            },
                            get left() {
                                return this.firstInstance?.left;
                             },
                            get initialOrder() {
                                return this.children![0]?.initialOrder
                            },
                            set initialOrder(pValue) {
                                if (pValue != undefined) {
                                    this.children?.forEach((col, index) => {
                                        col.initialOrder = pValue + index;
                                    });
                                }
                            },
                            children: [currentInstance],
                            get firstInstance() {
                                return this.children.find(x => !x.hide);
                            }
                        });
                        if (currentInstance.colId && !currentInstance.hide && this._movedInstance == null) {
                            const cachedCol = this._cachedColumns[currentInstance.colId];
                            cachedCol?.groupElements.push(...Array.from(this.container!.querySelectorAll<HTMLElement>(`[o365-header-group-id="${group.groupId}"][o365-header-group="${groupRowIndex}-${orderInRow}"]`)));
                            this._orderedColumnGroups[groupRowIndex].at(-1)!._groupCellsAppended = true;
                        }
                    }
                    currentInstance = this._orderedColumnGroups[groupRowIndex].at(-1)!;
                }
            } else if (!col.hide) {
                for (let i = 0; i < columnGroupRows; i++) {
                    if (this._orderedColumnGroups[i] == null) { this._orderedColumnGroups[i] = []; }
                    this._orderedColumnGroups[i].push(this._orderedColumns.at(colIndex)!);
                }
            }
        });

        this._updateGroupCells();
    }

    private _initDragImage() {
        if (this._dragImage) { return; }
        this._dragImage = document.createElement('div');
        this._dragImage.id = 'gridColumnDragImage';
        this._dragImage.classList.add('o365-grid-drag-image', 'rounded-2');
        const labelContainer = document.createElement('div');
        labelContainer.classList.add('drag-image-label-container', 'text-truncate')
        this._dragImageLabel = document.createElement('span');
        this._dragImageLabel.classList.add('text-truncate');

        this._dragImage.append(labelContainer);
        labelContainer.append(this._dragImageLabel);
    }

    private _isOrderEvent(pEvent: DragEvent) {
        return pEvent.dataTransfer != null && pEvent.dataTransfer.types.includes('o365-nt/column-order')
    }

    private _moveColumn() {
        if (this._movedInstance == null) { return; }
        this._isMoving = true;
        let toIndex = this._orderedColumns.findIndex(x => x.colId == this._currentOverColumn);
        const fromIndex = this._movedInstance.order!;

        if (isColumnGroup(this._movedInstance)) {
            if (this._prevDirection == 'right') {
                toIndex -= (this._movedInstance.children!.length - 1);
            }
            const cols = this._orderedColumns.splice(fromIndex, this._movedInstance.children!.length);
            this._movedInstance.order = toIndex;
            this._orderedColumns.splice(toIndex, 0, ...cols);
        } else {
            this._orderedColumns.splice(fromIndex, 1);
            this._movedInstance.order = toIndex;
            this._orderedColumns.splice(toIndex, 0, this._movedInstance);
        }

        let sumLeft = 0;
        let sumRight = 0;
        let sumCenter = 0;
        this._orderedColumns.forEach(col => {
            if (col.hide) { return; }
            const cachedCol = this._cachedColumns[col.colId!];
            switch (col.pinned) {
                case 'left':
                    cachedCol.left = sumLeft;
                    sumLeft += col.width ?? 0;
                    break;
                case 'right':
                    cachedCol.left = sumRight;
                    sumRight += col.width ?? 0;
                    break;
                default:
                    cachedCol.left = sumCenter;
                    sumCenter += col.width ?? 0;
            }
        });
        // this._orderedColumnGroups
        setTimeout(() => {
            this._isMoving = false;
        }, 201);
    }

    private _updateGroupCells() {
        this._orderedColumnGroups.forEach((groupRow, index) => {
            if (this.container == null) { return; }
            const row = this.container.querySelector(`.pinned-none.o365-header-group-row-cache[data-o365-rowindex="${index}"]`)
            const rowLeft = this.container.querySelector(`.pinned-left.o365-header-group-row-cache[data-o365-rowindex="${index}"]`)
            const rowRight = this.container.querySelector(`.pinned-right.o365-header-group-row-cache[data-o365-rowindex="${index}"]`)
            if (row == null) { return; }
            // Remove previous cells
            Array.from(row.children).forEach(el => el.remove());
            if (rowLeft) {
                Array.from(rowLeft.children).forEach(el => el.remove());
            }
            if (rowRight) {
                Array.from(rowRight.children).forEach(el => el.remove());
            }

            groupRow.forEach(columnGroup => {
                const node = document.createElement('div');
                node.classList.add('o365-header-group-cell', 'o365-header-cell');
                if (columnGroup.groupId) {
                    node.classList.add('group-cell');
                }
                const span = document.createElement('span');
                span.classList.add('o365-header-cell-text', 'text-truncate');
                span.innerText = columnGroup.groupId ? columnGroup.title! : '';
                node.append(span);
                node.style.left = columnGroup.left + 'px';
                node.style.width = columnGroup.width + 'px';
                if (columnGroup.pinned === 'left') {
                    rowLeft?.append(node);
                } else if (columnGroup.pinned === 'right') {
                    rowRight?.append(node);
                } else {
                    row.append(node);
                }
            });
        });
    }

    /** 
     * Add fixed heights for multiline headers during drags.
     * Returns a cleanup function that will remove the added styles.
     */
    private _addMultilineStyles() {
        let rowHeight = Object.values(this._cachedColumns).reduce((height: number, column) => {
            if (column.type != 'column') { return height; }
            const elements = column.elements;
            const parsedHeight = elements[0].parentElement?.clientHeight;
            if (parsedHeight != null && parsedHeight > height) { height = parsedHeight; }
            return parsedHeight ?? 34;
        }, 34)
        Object.values(this._cachedColumns).forEach(col => {
            if (col.type != 'column') { return; }
            col.elements[0].style.height = `${rowHeight}px`;
            if (col.elements[0].parentElement) {
                col.elements[0].parentElement.style.height = `${rowHeight}px`;
            }
            const column = this._dataGridControl.dataColumns.getColumn(col.id);
            requestAnimationFrame(() => {
                col.elements[0].style.position = 'absolute';
                col.elements[0].style.left = `${column?.left}px`;
            });
        });

        return () => {
            Object.values(this._cachedColumns).forEach((column) => {
                if (column.type != 'column') { return; }
                const elements = column.elements;
                if (elements[0].parentElement) {
                    elements[0].parentElement.style.height = '';
                }
                elements[0].style.height = '';
                elements[0].style.left = '';
                elements[0].style.position = '';
            });
        };
    }

    private _initializeAutoScroll() {
        if (this._movedInstance == null || this._movedInstance.pinned != null) { return; }
        const gridBody = this.container?.querySelector(this._dataGridControl._gridQuery('.o365-grid-body', true)) as HTMLElement;
        if (gridBody == null) { return; }
        const widthScrollBar = gridBody.querySelector<HTMLElement>(this._dataGridControl._gridQuery(".o365-body-horizontal-scroll-viewport"));
        if (widthScrollBar == null || widthScrollBar.clientWidth == widthScrollBar.scrollWidth) { return; }
        const rect = widthScrollBar.getBoundingClientRect();
        const leftBoundry = rect.x + 100;
        const rightBoundry = leftBoundry + rect.width - 100;
        let scrollInterval: number | null = null;
        let direction: null | 'left' | 'right' = null;
        let scrollBy = 10;
        let scrollDuration = 0;

        const trackDuration = () => {
            switch (scrollDuration) {
                case 500:
                    scrollBy = 15;
                    break;
                case 1000:
                    scrollBy = 20;
                    break;
                case 2000:
                    scrollBy = 25;
                    break;
            }
            if (scrollDuration < 3000) {
                scrollDuration += 10;
            }
        }

        const initScroll = (pDirection: 'left' | 'right') => {
            if (direction != pDirection) {
                if (scrollInterval) { clearInterval(scrollInterval); }
                direction = pDirection;
                scrollBy = 10;
                scrollDuration = 0;
                scrollInterval = setInterval(() => {
                    trackDuration();
                    if (pDirection == 'left') {
                        widthScrollBar.scrollLeft -= scrollBy;
                    } else {
                        widthScrollBar.scrollLeft += scrollBy;
                    }
                }, 10);
            }
        }

        this._dragEndTasks.push(addEventListener(window, 'dragover', (pEvent) => {
            if (pEvent.x <= leftBoundry) {
                initScroll('left');
            } else if (pEvent.x >= rightBoundry) {
                initScroll('right');
            } else if (scrollInterval) {
                direction = null;
                clearInterval(scrollInterval);
            }
        }));
        this._dragEndTasks.push(() => {
            if (scrollInterval) {
                clearInterval(scrollInterval);
            }
        });
        
    }

    cancelColumnMove() {
        console.warn('Not to be used anymore');
    }
}

function isColumnGroup(pInstance: OrderedColumnPolyfill | OrderedColumnGroupPolyfill): pInstance is OrderedColumnGroupPolyfill {
    return pInstance?.hasOwnProperty('groupId');
}

export type OrderedColumnPolyfill = {
    colId: string,
    order: number,
    pinned?: 'left' | 'right' | null,
    title: string,
    hide?: boolean,
    parentGroupId?: string,
    element?: HTMLElement,
    width?: number,
    left?: number,
    initialOrder?: number,
}

export type OrderedColumnGroupPolyfill = {
    colId?: string
    location?: [number, number],
    groupId?: string,
    order?: number,
    pinned?: 'left' | 'right' | null,
    title?: string,
    hide?: boolean,
    parentGroupId?: string,
    element?: HTMLElement,
    width?: number,
    left?: number,
    initialOrder?: number,
    children?: (OrderedColumnPolyfill | OrderedColumnGroupPolyfill)[]
    _groupCellsAppended?: boolean;
    firstInstance: OrderedColumnPolyfill | OrderedColumnGroupPolyfill
};

class CachedColumn {
    get type(): 'column' { return 'column'; }

    get left() { return this.elements[0]?.style.left ? +this.elements[0]?.style.left : this.elements[0]?.style.left; }
    set left(pVal) {
        this.groupElements.forEach(el => {
            el.style.left = pVal ? `${pVal}px` : '';
            el.dataset.left = `${pVal}`;
        });
        this.elements.forEach(el => {
            el.style.left = pVal ? `${pVal}px` : '';
            el.dataset.left = `${pVal}`;
        });
    }

    id: string;
    elements: HTMLElement[];
    groupElements: HTMLElement[];
    width: number;
    constructor(pId: string, pElements: HTMLElement[], pWidth: number, pGroupElements?: HTMLElement[]) {
        this.id = pId;
        this.elements = pElements;
        this.width = pWidth;
        this.groupElements = pGroupElements ?? [];
    }

    addClass(pClassName: string) {
        this.elements.forEach(el => el.classList.add(pClassName));
    }
    removeClass(pClassName: string) {
        this.elements.forEach(el => el.classList.remove(pClassName));
    }
}

//-------------------------------------------------------
// --- OLD COLUMN MOVE                                ---
//-------------------------------------------------------

// import type DataColumns from 'o365.controls.DataGrid.DataColumns.ts';
// import DataColumnGroup from 'o365.controls.DataGrid.ColumnGroup.ts';

// export default class ColumnMove {

//     container: HTMLElement;
//     dataColumns: DataColumns;
//     prevX: number|null;
//     movedColumn: any;
//     overColumnField: string|null;
//     orderedColumns: any[];
//     moveToField: string|null;
//     movedElement: any;
//     columnCache: Record<string, HTMLElement[]>;
//     prevIndex: number;
//     prevDirection: any;
//     prevMove: any;
//     dragImage: HTMLElement;
//     dragImageLabel: HTMLElement;
//     isMultilineHeader: boolean;

//     constructor(container, columns, isMultilineHeader) {
//         this.container = container;
//         this.dataColumns = columns;
//         this.prevX = null;
//         this.movedColumn = null;
//         this.overColumnField = null;
//         this.orderedColumns = [];
//         this.moveToField = null;
//         this.isMultilineHeader = isMultilineHeader ?? false;

//         this.movedElement = null;

//         this._onDragEnd = this._onDragEnd.bind(this);

//         this.columnCache = {};
//         this.initDragImage();
//         this.prevIndex = 0;
//         this.prevDirection = null;
//         this.prevMove = null;
//         this.init();
//     }


//     initDragImage() {
//         this.dragImage = document.createElement('div');
//         this.dragImage.id = "gridColumnDragImage";
//         this.dragImage.classList.add('o365-grid-drag-image', 'rounded-2');
//         const labelContainer = document.createElement('div');
//         labelContainer.classList.add('drag-image-label-container', 'text-truncate')
//         this.dragImageLabel = document.createElement('span');
//         this.dragImageLabel.classList.add('text-truncate');

//         this.dragImage.append(labelContainer);
//         labelContainer.append(this.dragImageLabel);
//     }

//     cacheColumns() {
//         this.columnCache = {};
//         this.orderedColumns = [];


//         this.dataColumns.columns.forEach(col => {
//             if (!col.hide) {
//                 this.columnCache[col.colId] = Array.from(this.container.querySelectorAll("[o365-field='" + col.colId + "'][data-o365-colindex]"));
//                 const element: HTMLElement = this.container.querySelector(`.o365-header-cell.o365-header-cell-container[o365-field="${col.colId}"][data-o365-colindex]`)!;
//                 element.dataset.left = col.left as any;
//                 this.orderedColumns.push({
//                     field: col.colId,
//                     order: col.order,
//                     width: col.width+col.widthAdjustment,
//                     pinned: col.pinned ? col.pinned : null,
//                     title: col.headerName ?? col.field,

//                     parentGroupId: col.parentGroupId,
//                     element: element,
//                     get left() { return parseInt(this.element.style.left) || parseInt(this.element.dataset.left) },
//                 })
//             } else {
//                 this.orderedColumns.push({
//                     field: col.colId,
//                     order: col.order,
//                     pinned: col.pinned ? col.pinned : null,
//                     title: col.headerName ?? col.field,
//                     parentGroupId: col.parentGroupId,
//                     hide: true
//                 });
//             }
//         });

//         if (this.isMultilineHeader) {
//             this._addMultilineStyles();
//         }
//     }

//     renderGroups() {
//         const computedGroups = [...this.dataColumns.columnGroups].reverse().reduce((groupRows, row, rowIndex) => {
//             const previousRow = groupRows[rowIndex - 1] ?? this.orderedColumns;

//             const groupIdFromCol = (col) => {
//                 return `${col.parentGroupId}${col.pinned}`
//             };

//             const computedGroupRow = previousRow.reduce((groups, col) => {
//                 if (col.hide) { return groups; }
//                 if (col.parentGroupId) {
//                     const lastGroup = groups[groups.length - 1];
//                     if (lastGroup?.groupId) {
//                         if (lastGroup.groupId === groupIdFromCol(col)) {
//                             lastGroup.children.push(col);
//                             return groups;
//                         }
//                     }
//                     const initialGroup = row.find(x => x.groupId === col.parentGroupId);
//                     if (!initialGroup) {
//                         groups.push(col);
//                         return groups;
//                     }
//                     const group = new DataColumnGroup({
//                         groupId: groupIdFromCol(col),
//                         children: [col],
//                         headerName: initialGroup.headerName ?? initialGroup.headername,
//                         parentGroupId: initialGroup.parentGroupId
//                     });
//                     groups.push(group);
//                 } else {
//                     groups.push(col);
//                 }
//                 return groups;
//             }, []);

//             groupRows.push(computedGroupRow);
//             return groupRows
//         }, []).reverse();

//         computedGroups.forEach((groupRow, index) => {
//             const row = this.container.querySelector(`.pinned-none.o365-header-group-row-cache[data-o365-rowindex="${index}"]`)
//             const rowLeft = this.container.querySelector(`.pinned-left.o365-header-group-row-cache[data-o365-rowindex="${index}"]`)
//             const rowRight = this.container.querySelector(`.pinned-right.o365-header-group-row-cache[data-o365-rowindex="${index}"]`)
//             if (row == null) { return; }
//             Array.from(row.children).forEach(el => el.remove());
//             if (rowLeft) {
//                 Array.from(rowLeft.children).forEach(el => el.remove());
//             }
//             if (rowRight) {
//                 Array.from(rowRight.children).forEach(el => el.remove());
//             }
//             groupRow.forEach((column) => {
//                 const node = document.createElement('div');
//                 node.classList.add('o365-header-group-cell', 'o365-header-cell');
//                 if (column.groupId) { node.classList.add('group-cell'); }
//                 const span = document.createElement('span');
//                 span.classList.add('o365-header-cell-text', 'text-truncate');
//                 span.innerText = column.groupId ? column.headerName : '';
//                 node.append(span);
//                 node.style.left = column.left + 'px';
//                 node.style.width = column.width + 'px';
//                 if (column.pinned === 'left') {
//                     rowLeft?.append(node);
//                 } else if (column.pinned === 'right') {
//                     rowRight?.append(node);
//                 } else {
//                     row.append(node);
//                 }
//             });
//         });
//     }

//     setClassForCachedColumns(pColumnName?, pClassName?, pRemove?) {
//         if (!pRemove) {
//             this.columnCache[pColumnName].forEach(el => {
//                 el.classList.add(pClassName);
//             });
//         } else {
//             this.columnCache[pColumnName].forEach(el => {
//                 el.classList.remove(pClassName);
//             });
//         }
//     }

//     setLeftProp(pColumnName, pLeft) {
//         if (this.columnCache[pColumnName].length === 0) return;
//         if (this.columnCache[pColumnName][0].style.left === pLeft.toString() + "px") return;
//         this.columnCache[pColumnName].forEach(el => {
//             el.style.left = pLeft + "px";
//             el.dataset.left = pLeft;
//         });
//     }


//     moveColumn() {
//         const vColumnToIndex = this.orderedColumns.findIndex(x => x.field === this.overColumnField);
//         const vColumnFromIndex = this.orderedColumns.findIndex(x => x.field === this.movedColumn.field);

//         let vSumLeft = 0, vSum = 0, vSumRight = 0;
//         this.orderedColumns.splice(vColumnFromIndex, 1);
//         this.movedColumn.order = vColumnToIndex;
//         this.orderedColumns.splice(vColumnToIndex, 0, this.movedColumn);

//         this.orderedColumns.forEach(col => {
//             if (col.hide) { return; }
//             if (col.pinned === 'left') {
//                 this.setLeftProp(col.field, vSumLeft);
//                 vSumLeft += col.width;
//             } else if (col.pinned === 'right') {
//                 this.setLeftProp(col.field, vSumRight);
//                 vSumRight += col.width;
//             } else {
//                 this.setLeftProp(col.field, vSum);
//                 vSum += col.width;
//             }

//         });

//         if (this.dataColumns.hasGroupedColumns) {
//             this.renderGroups();
//         }
//     }

//     init() {
//         this.container.addEventListener("dragstart", evt => {
//             const target = <HTMLElement>evt.target;
//             const vClosest = target?.closest ? <HTMLElement>target?.closest("[o365-field][data-o365-colindex]") : null;
//             if (!vClosest || !vClosest.classList.contains('o365-header-cell')) { return; }
//             this.movedElement = evt.target;

//             this.cacheColumns();
//             if (evt.dataTransfer == null) { return; }
//             const colId = (evt.target as HTMLElement).getAttribute('o365-field');
//             if (colId == null) { return; }
//             evt.dataTransfer.effectAllowed = "all";
//             evt.dataTransfer.clearData();
//             evt.dataTransfer.setData('text/plain', colId);
//             evt.dataTransfer.setData('o365-nt/group-by-column', JSON.stringify({ colId: colId }));
//             this.prevX = evt.clientX;

//             if (this.dataColumns.hasGroupedColumns) {
//                 this.container.querySelectorAll('.o365-header-group-row').forEach(row => row.classList.add('d-none'));

//                 const createRowNodes = (pinned = 'none') => {
//                     const rowNodes = this.dataColumns.columnGroups.map((group, index) => {
//                         const node = document.createElement('div');
//                         node.dataset.o365Rowindex = index + '';
//                         node.classList.add('o365-header-row', 'o365-header-group-row', 'o365-header-group-row-cache', `pinned-${pinned}`);
//                         return node;
//                     });
//                     return rowNodes;
//                 };
//                 this.container.querySelector('.o365-header-viewport .o365-grid-container')?.prepend(...createRowNodes());
//                 this.container.querySelector('.o365-header-pinned-left')?.prepend(...createRowNodes('left'));
//                 this.container.querySelector('.o365-header-pinned-right')?.prepend(...createRowNodes('right'));

//                 this.renderGroups();
//             }

//             this.container.classList.add('o365-columns-moving');



//             this.movedColumn = this.orderedColumns.find(x => x.field === evt.target?.['getAttribute']("o365-field"));
//             this.movedColumn.initialOrder = this.movedColumn.order;

//             this.dragImageLabel.textContent = this.movedColumn.title;
//             this.dragImage.style.width = this.movedColumn.width + 'px';
//             document.body.appendChild(this.dragImage);
//             evt.dataTransfer.setDragImage(this.dragImage, 0, 0);
//             evt.dataTransfer.setData('o365-nt/column-order', JSON.stringify({colId: this.movedColumn.field, initialOrder: this.movedColumn.order}));



//             this.setClassForCachedColumns(this.movedColumn.field, "column-moving");

//             window.addEventListener('dragend', this._onDragEnd);

//         }, false);

//         this.container.addEventListener("dragenter", evt => {
//             if (evt.dataTransfer == null) { return; }
//             evt.dataTransfer.dropEffect = "copy";

//         });


//         this.container.addEventListener('dragover', (evt) => {
//             evt.preventDefault();

//             const target = <HTMLElement>evt.target;
//             const vClosest = <HTMLElement>target.closest("[o365-field][data-o365-colindex]");
//             if (!vClosest || !vClosest.classList.contains('o365-header-cell')) { return; }

//             if (vClosest === this.movedElement) return;

//             if (this.prevX === evt.clientX) return;

//             const vTargetIndex = parseInt(vClosest?.dataset.o365Colindex ?? '');
//             const vField = vClosest.getAttribute("o365-field");
//             if (!vField) return;


//             let vDirection: string|null = null;


//             if (evt.clientX > (this.prevX ?? 0)) {
//                 vDirection = "right";
//             } else {
//                 vDirection = "left";
//             }

//             const vDirectionChanged = vDirection !== this.prevDirection;

//             if (!vDirectionChanged && vTargetIndex === parseInt(this.movedElement.dataset.o365Colindex)) {
//                 return;
//             }



//             if (vTargetIndex === 0 || vTargetIndex >= this.orderedColumns.length - 1) {
//                 if (evt.dataTransfer == null) { return; }
//                 evt.dataTransfer.dropEffect = "none";
//                 return;
//             }

//             const vColumn = this.orderedColumns.find(x => x.field === vField);
//             if (!vColumn) {
//                 return;
//             }

//             if (this.movedColumn.pinned != vColumn.pinned || (this.movedColumn.parentGroupId != vColumn.parentGroupId)) {
//                 if (evt.dataTransfer == null) { return; }
//                 evt.dataTransfer.dropEffect = "none";
//                 return;
//             }




//             if (this.overColumnField === vField && this.prevDirection === vDirection) return;

//             this.overColumnField = vField;

//             this.moveColumn();
//             this.prevDirection = vDirection;

//             this.prevX = evt.clientX;

//         }, false);

//         this.container.addEventListener("drop", evt => {
//             if (this.dataColumns.hasGroupedColumns) {
//                 this.container.querySelectorAll('.o365-header-group-row-cache').forEach(row => row.remove());
//                 this.container.querySelectorAll('.o365-header-group-row').forEach(row => row.classList.remove('d-none'));
//             }
//             if (!this.movedColumn) return;
//             window.removeEventListener('dragend', this._onDragEnd);


//             this.container.classList.remove('o365-columns-moving');

//             this.setClassForCachedColumns(this.movedColumn.field, "column-moving", true);


//             this.orderedColumns.forEach((col, index) => {
//                 const isMovedCol = this.movedColumn.field === col.field;
//                 if (isMovedCol) {
//                     this.dataColumns.getColumn(col.field)?.trackChange('order', index);
//                 }
//                 this.dataColumns.setColumnProperty(col.field, "order", index);
//             })
//             if (this.isMultilineHeader) {
//                 this._clearMultilineStyles();
//             }



//             this.movedColumn = null;
//             this.orderedColumns = [];
//             this.overColumnField = null;
//             this.dragImage.remove();
//             this.moveToField = null;
//             this.columnCache = {};
//             this.prevMove = null;
//             this.prevDirection = null;

//         }, false);

//     }

//     private _onDragEnd = (e: DragEvent) => {
//         window.removeEventListener('dragend', this._onDragEnd);
        
//         if (this.dataColumns.hasGroupedColumns) {
//             this.container.querySelectorAll('.o365-header-group-row-cache').forEach(row => row.remove());
//             this.container.querySelectorAll('.o365-header-group-row').forEach(row => row.classList.remove('d-none'));
//         }
//         if (!this.movedColumn) return;

//         this.container.classList.remove('o365-columns-moving');

//         this.setClassForCachedColumns(this.movedColumn.field, "column-moving", true);

//         this.orderedColumns.forEach((col, index) => {
//             const isMovedCol = this.movedColumn.field === col.field;
//             if (isMovedCol) {
//                 this.dataColumns.getColumn(col.field)?.trackChange('order', index);
//             }
//             this.dataColumns.setColumnProperty(col.field, "order", index);
//         });
//         if (this.isMultilineHeader) {
//             this._clearMultilineStyles();
//         }


//         this.movedColumn = null;
//         this.orderedColumns = [];
//         this.overColumnField = null;
//         this.dragImage.remove();
//         this.moveToField = null;
//         this.columnCache = {};
//         this.prevMove = null;
//     }

//     cancelColumnMove(skipOrderSet = false) {
//         window.removeEventListener('dragend', this._onDragEnd);

//         if (this.dataColumns.hasGroupedColumns) {
//             this.container.querySelectorAll('.o365-header-group-row-cache').forEach(row => row.remove());
//             this.container.querySelectorAll('.o365-header-group-row').forEach(row => row.classList.remove('d-none'));
//         }
//         if (!this.movedColumn) return;

//         this.container.classList.remove('o365-columns-moving');

//         this.setClassForCachedColumns(this.movedColumn.field, "column-moving", true);

//         if (!skipOrderSet) {
//             this.orderedColumns.forEach((col, index) => {
//                 this.dataColumns.setColumnProperty(col.field, "order", index);
//             })
//         }
//         if (this.isMultilineHeader) {
//             this._clearMultilineStyles();
//         }

//         this.movedColumn = null;
//         this.orderedColumns = [];
//         this.overColumnField = null;
//         this.dragImage.remove();
//         this.moveToField = null;
//         this.columnCache = {};
//         this.prevMove = null;
//     }

//     private _addMultilineStyles() {
//         let rowHeight = Object.values(this.columnCache).reduce((height: number, elements: HTMLElement[]) => {
//             const parsedHeight = elements[0].parentElement?.clientHeight;
//             if (parsedHeight != null && parsedHeight > height) { height = parsedHeight; }
//             return parsedHeight;
//         }, 34) 

//         Object.entries(this.columnCache).forEach(([key, elements]) => {
//             elements[0].style.height = rowHeight+'px';
//             if (elements[0].parentElement) {
//                 elements[0].parentElement.style.height = rowHeight+'px';
//             }
//             const column = this.dataColumns.getColumn(key);
//             window.requestAnimationFrame(() => {
//                 elements[0].style.position = 'absolute'
//                 elements[0].style.left = column?.left+'px';
//             });
//         });
//     }

//     private _clearMultilineStyles() {
//         Object.values(this.columnCache).forEach((elements: HTMLElement[]) => {
//             if (elements[0].parentElement) {
//                 elements[0].parentElement.style.height = '';
//             }
//             elements[0].style.height = '';
//             elements[0].style.left = '';
//             elements[0].style.position = '';
//         });
//     }
// }